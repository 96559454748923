import  secureLocalStorage  from  "react-secure-storage";

export const userServices = {
  login,
  logout,
  register,
  socialLogin,
  checkLoginExist,
  checkEmailExist,
  recoverPassword,
};

function logout() {
  // remove user from local storage to log user out
  secureLocalStorage.removeItem('user');
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // eslint-disable-next-line no-restricted-globals
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function handleResponseExist(response) {

  return response.json().then(res => {
    if (response.status === 200) {
      return res.hasOwnProperty('id');
    } else {
      const error = (res && res.message) || response.statusText;
      return Promise.reject(error);
    }
  });
}

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({login: username, password})
  };

  return fetch(`${process.env.REACT_APP_API_URL}/accounts/authenticate`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      secureLocalStorage.setItem('user', JSON.stringify(user))
      return user;
    });
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(user)
  };

  return fetch(`${process.env.REACT_APP_API_URL}/accounts/register`, requestOptions)
  .then(handleResponse)
}

function socialLogin(socialUser) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({profile: socialUser})
  };

  return fetch(`${process.env.REACT_APP_API_URL}/accounts/authenticate/social`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      secureLocalStorage.setItem('user', JSON.stringify(user));
      return user;
    });
}

export function checkLoginExist(login) {
  const requestOptions = {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users/find?login=${login}`, requestOptions).then(handleResponseExist);
}

export function checkEmailExist(email) {
  const requestOptions = {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users/find?email=${email}`, requestOptions).then(handleResponseExist);
}

function recoverPassword(email) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({login: email})
  };

  return fetch(`${process.env.REACT_APP_API_URL}/reset-password`, requestOptions)
    .then(res => {
      if (res.status === 200) {
        return 'An email has been sent to your adress'
      } else {
        const error = (res && res.message) || res.statusText;
        return Promise.reject(error);
      }
    });
}