import { authHeader } from "../helpers";

export const playersService = {
  fetchContent,
  like,
  unlike,
  sendMessage,
  playerContentSeen,
};

function fetchContent(iv, content) {
  const requestOptions = {
    method: 'GET',
  };

  return fetch(`${process.env.REACT_APP_API_URL}/play?iv=${iv}&content=${content}`, requestOptions)
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return res.text().then((msg) => {
          if (msg) return Promise.reject(msg);
          const error = (res && res.message) || res.statusText;
          return Promise.reject(error);
        })
      }
    });
}

function like(iv, content, id) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({
      id,
    })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/play/like?iv=${iv}&content=${content}`, requestOptions)
    .then(res => {
      if (res.status === 200) {
        return Promise.resolve('OK');
      } else {
        return res.text().then((msg) => {
          if (msg) return Promise.reject(msg);
          const error = (res && res.message) || res.statusText;
          return Promise.reject(error);
        })
      }
    });
}

function unlike(iv, content, id) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({
      id,
    })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/play/unlike?iv=${iv}&content=${content}`, requestOptions)
    .then(res => {
      if (res.status === 200) {
        return Promise.resolve('OK');
      } else {
        return res.text().then((msg) => {
          if (msg) return Promise.reject(msg);
          const error = (res && res.message) || res.statusText;
          return Promise.reject(error);
        })
      }
    });
}

function sendMessage(iv, content, id, message) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({
      id,
      message,
    })
  };

  return fetch(`${process.env.REACT_APP_API_URL}/play/message?iv=${iv}&content=${content}`, requestOptions)
    .then(res => {
      if (res.status === 200) {
        return Promise.resolve('OK');
      } else {
        return res.text().then((msg) => {
          if (msg) return Promise.reject(msg);
          const error = (res && res.message) || res.statusText;
          return Promise.reject(error);
        })
      }
    });
    
}

function playerContentSeen(token, contentId) {
  const requestOptions = {
    method: 'PUT',
    headers: {'Content-Type': 'application/json'},
  };

  return fetch(`${process.env.REACT_APP_API_URL}/content/${contentId}/viewed`, requestOptions)
    .then(res => {
      if (res.status < 300) {
        return Promise.resolve();
      } else {
        const error = (res && res.message) || res.statusText;
        return Promise.reject(error);
      }
    });
}