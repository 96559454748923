import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import MessageIcon from '@mui/icons-material/Message'
import { useDispatch, useSelector } from 'react-redux';
import { Badge, IconButton } from '@mui/material';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { messageHasBeenRead, openModal, playingStatus } from '../../reducers/players/playersSlice';
import { likeContent, unlikeContent } from '../../reducers/players/playersThunks';

const PREFIX = 'SideBar';

const classes = {
  videoSidebar: `${PREFIX}-videoSidebar`,
  videoSidebarButtonLike: `${PREFIX}-videoSidebarButtonLike`,
  videoSidebarButtonMessage: `${PREFIX}-videoSidebarButtonMessage`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  closeIconButton: `${PREFIX}-closeIconButton`,
  textfieldStyle: `${PREFIX}-textfieldStyle`,
  iconBackground: `${PREFIX}-iconBackground`,
  likeCounter: `${PREFIX}-likeCounter`,
  iconEventsBlocker: `${PREFIX}-iconEventsBlocker`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.videoSidebar}`]: {
    position: 'absolute',
    bottom: '200px',
    right: '0',
    color: 'white',
    zIndex: '1200',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  [`& .${classes.videoSidebarButtonLike}`]: {
    padding: '15px',
    textAlign: 'center',
    position: 'absolute',
    bottom: '100px',
    right: '0px',
  },
  [`& .${classes.videoSidebarButtonMessage}`]: {
    padding: '5px 15px',
    textAlign: 'center',
  },
  [`& .${classes.dialogTitle}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '2rem',
    padding: '1rem',
  },

  [`& .${classes.closeIconButton}`]: {
    paddingRight: '0',
    transform: 'translateY(-2px)',
  },

  [`& .${classes.textfieldStyle}`]: {
    marginTop: '1rem',
    width: '100%',
  },
  [`& .${classes.iconBackground}`]: {
    backgroundColor: 'rgba(85, 85, 85, 0.3)',
    width: '48px',
    height: '48px',
    borderRadius: '50%',
  },
  [`& .${classes.iconEventsBlocker}`]: {
    pointerEvents: 'none'
  },
  [`& .${classes.likeCounter}`]: {
    position: 'absolute',
    top: '17px',
    right: '28px',
  },
}));

const SideBar = ({ token }) => {

  const dispatch = useDispatch()
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [requestLogin, setRequestLogin] = useState(false);

  const loggedIn = useSelector(state => state.user.currentUser);
  const currentElement = useSelector(state => state.players.playlist[state.players.currentPlayingIndex])
  const currentIndex = useSelector(state => state.players.currentPlayingIndex);
  const messageRead = useSelector(state => state.players.playlist[state.players.currentPlayingIndex].read)
  const prevModalLogin = useRef();

  const callLikeUnlike = async () => {
    if (!currentElement.like) {
      try {
        const thunk = await dispatch(likeContent({ iv: token.iv, content: token.content, id: currentElement.id, storyIndex: currentIndex }))
        if (thunk.meta.requestStatus === 'rejected')
          throw new Error('offline')
      } catch {
        enqueueSnackbar(t('toasts.likeError'), { variant: 'error' });
      }
    } else {
      try {
        const thunk = await dispatch(unlikeContent({ iv: token.iv, content: token.content, id: currentElement.id, storyIndex: currentIndex }))
        if (thunk.meta.requestStatus === 'rejected')
          throw new Error('offline')
      } catch {
        enqueueSnackbar(t('toasts.dislikeError'), { variant: 'error' });
      }
    }
  }

  useEffect(() => {
    if (prevModalLogin.current !== loggedIn && requestLogin) {
      setRequestLogin(false);
      callLikeUnlike();
    }
    prevModalLogin.current = loggedIn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn])

  const handleLike = () => {
    if (loggedIn) {
      callLikeUnlike();
    } else {
      setRequestLogin(true);
      if (currentElement.playing)
        dispatch(playingStatus());
      dispatch(openModal("auth"));
    }
  }

  const handleMessage = () => {
    if (loggedIn) {
      dispatch(openModal("message"))
    } else {
      dispatch(openModal("auth"));
    }
  }

  return (
    <Root className={classes.videoSidebar}>
      {currentElement.like ? (
        <div className={classes.videoSidebarButtonMessage}>
          <IconButton
            disableRipple
            className={classes.iconBackground}
            aria-label="Like"
            onClick={(e) => {
              e.stopPropagation();
              handleLike()
            }}
          >
            <FavoriteIcon color="primary" fontSize="large" className={classes.iconEventsBlocker} />
          </IconButton>
        </div>
      ) : (
        <div className={classes.videoSidebarButtonMessage}>
          <IconButton
            disableRipple
            className={classes.iconBackground}
            aria-label="Like"
            onClick={(e) => {
              e.stopPropagation();
              handleLike()
            }}
          >
            <FavoriteBorderIcon color="primary" fontSize="large" className={classes.iconEventsBlocker} />
          </IconButton>
        </div>
      )}
      {currentElement.countLike > 0 &&
        <div>
          {currentElement.countLike}
        </div>
      }
      {!currentElement.isPlanif &&
        <div className={classes.videoSidebarButtonMessage}>
          <IconButton
            disableRipple
            className={classes.iconBackground}
            aria-label="Like"
            onClick={(e) => {
              e.stopPropagation();
              handleMessage()
            }}
          >
            <MessageIcon color="primary" fontSize="large" className={classes.iconEventsBlocker} />
          </IconButton>
        </div>
      }
      {currentElement.type !== 'message' && currentElement.message &&
        <div className={classes.videoSidebarButtonMessage}>
          {currentElement.type === 'message' ?
            null
            : !messageRead ?
              <IconButton
                disableRipple
                className={classes.iconBackground}
                aria-label="Like"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(openModal('comment'));
                  dispatch(messageHasBeenRead(currentIndex));
                }}
              >
                <Badge badgeContent={1} color="secondary" overlap="circular">
                  <TextsmsIcon
                    color="primary"
                    fontSize="large"
                    className={classes.iconEventsBlocker}
                  />
                </Badge>
              </IconButton>
              :
              <IconButton
                disableRipple
                className={classes.iconBackground}
                aria-label="Like"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(openModal('comment'));
                  dispatch(messageHasBeenRead(currentIndex));
                }}
              >
                <TextsmsIcon
                  color="primary"
                  fontSize="large"
                  className={classes.iconEventsBlocker}
                />
              </IconButton>
          }
        </div>
      }
    </Root>
  );
}

export default SideBar