import React from 'react';
import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../reducers/players/playersSlice';

const PREFIX = 'ReadMessageDialog';

const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
  closeIconButton: `${PREFIX}-closeIconButton`,
  dialogContentContainer: `${PREFIX}-dialogContentContainer`,
  iconEventsBlocker: `${PREFIX}-iconEventsBlocker`
};

const StyledDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`& .${classes.dialogTitle}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '2rem',
    padding: '1rem',
  },
  [`& .${classes.closeIconButton}`]: {
    paddingRight: '0',
    transform: 'translateY(-2px)',
  },
  [`& .${classes.dialogContentContainer}`]: {
    padding: '16px',
    transform: 'translateY(-2px)',
    borderBottom: 'none',
  },
  [`& .${classes.iconEventsBlocker}`]: {
    pointerEvents: 'none'
  },
}))

const ReadMessage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modalInfo = useSelector(state => state.players.modalType)
  const currentElement = useSelector(state => state.players.playlist[state.players.currentPlayingIndex])

  return (
    <StyledDialog
      fullWidth
      onClose={() => dispatch(closeModal())}
      aria-labelledby="customized-custom-message-dialog-title"
      open={modalInfo ? true : false}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="body1">
          {t('messageDialog.someoneLetMessage')}
        </Typography>
        <IconButton
          aria-label="close"
          disableRipple
          className={classes.closeIconButton}
          onClick={() => dispatch(closeModal())}
        >
          <Close className={classes.iconEventsBlocker} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        className={classes.dialogContentContainer}
      >
        <Typography gutterBottom>
          {currentElement.message}
        </Typography>
      </DialogContent>
    </StyledDialog>
  )
}

export default ReadMessage;