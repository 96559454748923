import i18n from 'i18next';
import translateEN from './en.json';
import translateFR from './fr.json';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    languages: ['en', 'fr'],
    resources: {
      en: {
        translation: translateEN
      },
      fr: {
        translation: translateFR
      }
    }
  });


export default i18n;
