import { createSlice } from '@reduxjs/toolkit'
import secureLocalStorage from 'react-secure-storage';
import { login, recoverPassword, register, socialLogin } from './userThunks';

function initializeUserState() {
  const currentUser = JSON.parse(secureLocalStorage.getItem('user'));
  let initialState = {
    loading: false,
    currentUser: null,
    error: null,
    recoverySuccess: null,
  }

  if (currentUser)
    initialState.currentUser = currentUser
  return initialState;
}

export const userSlice = createSlice({
  name: 'user',
  initialState: initializeUserState(),
  reducers: {
    resetError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error
      })
      .addCase(socialLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(socialLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
      })
      .addCase(socialLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error
      })
      .addCase(register.pending, (state) => {
        state.loading = true;
      })
      .addCase(register.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(register.rejected, (state) => {
        state.loading = false;
      })
      .addCase(recoverPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(recoverPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.recoverySuccess = action.payload;
      })
      .addCase(recoverPassword.rejected, (state) => {
        state.loading = false;
        state.error = 'Recovery error';
      })
  },
})

// Action creators are generated for each case reducer function
export const { resetError } = userSlice.actions

export default userSlice.reducer