import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../reducers/players/playersSlice";
import { playersService } from "../../services";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next"
import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

const PREFIX = 'SendMessage';

const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
  closeIconButton: `${PREFIX}-closeIconButton`,
  textfieldStyle: `${PREFIX}-textfieldStyle`,
  sendMessageDialog: `${PREFIX}-sendMessageDialog`,
  dialogTitleTypo: `${PREFIX}-dialogTitleTypo`,
  sendButtonContainer: `${PREFIX}-sendButtonContainer`,
  notchedOutline: `${PREFIX}-notchedOutline`,
  titlePositionning: `${PREFIX}-titlePositionning`,
  iconEventsBlocker: `${PREFIX}-iconEventsBlocker`
};

const StyledDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`& .${classes.dialogTitle}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '2rem',
    padding: '1rem 1rem 0.5rem 1rem',
  },
  [`& .${classes.titlePositionning}`]: {
    width: '100%'
  },
  [`& .${classes.closeIconButton}`]: {
    paddingRight: '0',
    transform: 'translateY(-2px)',
  },

  [`& .${classes.textfieldStyle}`]: {
    marginTop: '1rem',
    width: '100%',
    background: '#323232',
    borderRadius: '4px',
  },
  [`& .${classes.notchedOutline}`]: {
    borderColor: '#D3AF5F',
    borderWidth: '1px',
  },
  [`& .${classes.sendMessageDialog}`]: {
    outline: '3px solid #D3AF5F',
    margin: '32px 0 32px 0',
    width: 'calc(100% - 14px)',
  },
  [`& .${classes.sendButtonContainer}`]: {
    padding: '0 24px 20px 0',
  },
  [`& .${classes.iconEventsBlocker}`]: {
    pointerEvents: 'none'
  },
}));

const SendMessage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [sendingMessage, setSendingMessage] = useState(false);
  const [comment, setComment] = useState('')
  const modalInfo = useSelector(state => state.players.modalType)
  const currentElement = useSelector(state => state.players.playlist[state.players.currentPlayingIndex])

  const handleSendMessage = () => {
    const search = new URLSearchParams(location.search);
    setSendingMessage(true);
    playersService.sendMessage(search.get('iv'), search.get('content'), currentElement.id, comment)
      .then(() => {
        setSendingMessage(false);
        setComment('');
        enqueueSnackbar(t('toasts.messageSuccess'), { variant: 'success' });
      })
      .catch(err => {
        setSendingMessage(false);
        enqueueSnackbar(t('toasts.messageError'), { variant: 'error' });
      })
  }

  return (
    <StyledDialog
      fullWidth
      className={classes.sendMessageDialog}
      onClose={() => dispatch(closeModal())}
      aria-labelledby="customized-dialog-title"
      open={modalInfo ? true : false}
      PaperProps={{ className: classes.sendMessageDialog }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography
        variant="body1"
        color='primary'
        align='center'
        className={classes.titlePositionning}>
          {t('messageDialog.title')}
        </Typography>
        <IconButton
          aria-label="close"
          disableRipple
          className={classes.closeIconButton}
          onClick={() => dispatch(closeModal())}
          size="large">
          <CloseIcon className={classes.iconEventsBlocker} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          {t('messageDialog.modalContent')}
        </Typography>
        <TextField
          className={classes.textfieldStyle}
          id="standard-multiline-static"
          label={t('messageDialog.placeholder')}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline
            }
          }}
          multiline
          variant='outlined'
          rows={6}
          value={comment}
          onChange={e => setComment(e.target.value)}
        />
      </DialogContent>
      <DialogActions className={classes.sendButtonContainer}>
        <Button
          variant="contained"
          onClick={() => handleSendMessage()}
          color="primary">
          {sendingMessage ? <CircularProgress /> : t('messageDialog.sendButton')}
        </Button>
      </DialogActions>
    </StyledDialog>
  );

}

export default SendMessage