import { createTheme, responsiveFontSizes } from "@mui/material/styles";
// default breakpoints
// value         |0px     600px    960px    1280px   1920px
// key           |xs      sm       md       lg       xl
// screen width  |--------|--------|--------|--------|-------->
// range         |   xs   |   sm   |   md   |   lg   |   xl
//

// / outputs {xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920}
let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#D3AF5F',
    },
  },
  typography: {
    fontFamily: [
      'Lato',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            "WebkitBoxShadow": "0 0 0 100px var(--primary-weak) inset",
            "WebkitTextFillColor": "var(--text-primary)",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          '&.Mui-disabled': {
            color: 'rgba(211,175,95,0.3)',
            backgroundColor: 'rgba(211,175,95,0.3)',
          },
        },
        containedPrimary: {
          backgroundColor: 'rgb(211,175,95)',
          color: 'rgb(48,48,48)',
        },
        contained: {
          '&.Mui-disabled': {
            color: 'rgba(48,48,48,0.5)',
            backgroundColor: 'rgba(211,175,95,0.3)',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-internal-autofill-selected': {
            'WebkitBoxShadow': '0 0 0 30px #383838 inset !important',
            'WebkitTextFillColor': 'white !important',
          },
          '&:-webkit-autofill': {
            'WebkitBoxShadow': '0 0 0 30px #383838 inset !important',
            'WebkitTextFillColor': 'white !important',
            caretColor: 'white',
          },
          '&:-webkit-autofill::first-line': {
            fontSize: '16px',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: 'rgba(255, 255, 255, 0.7)',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: '#D3AF5F',
          }
        }
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorSecondary: '#D3AF5F',
      },
    },
    MuiSpeedDialAction: {
      styleOverrides: {
        staticTooltipLabel: {
          color: '#D3AF5F',
          fontWeight: 800,
        }
      },
    },
  },
});

theme = responsiveFontSizes(theme);
export { theme };
