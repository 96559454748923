import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import {Container, Dialog, AppBar, Toolbar, IconButton, Typography, Slide} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { useViewport } from '../../helpers';

const PREFIX = 'ResponsiveDialogManager';

const classes = {
  appBar: `${PREFIX}-appBar`,
  appBarMobile: `${PREFIX}-appBarMobile`,
  title: `${PREFIX}-title`,
  overflowMod: `${PREFIX}-overflowMod`,
  toolbarContainer: `${PREFIX}-toolbarContainer`,
  iconEventsBlocker: `${PREFIX}-iconEventsBlocker`
};

const StyledDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`& .${classes.appBar}`]: {
    position: 'relative',
  },

  [`& .${classes.appBarMobile}`]: {
    position: 'relative',
    paddingTop: '40px',
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.overflowMod}`]: {
    overflow: 'auto',
    maxWidth: '100%',
    height: '100%',
    paddingBottom: '2rem',
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D3AF5F',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgb(181 154 79 / 58%)',
      bordeRadius: '10px',
    },
  },
  [`& .${classes.toolbarContainer}`]: {
    display: 'flex',
    width: '100%',
  },
  [`& .${classes.iconEventsBlocker}`]: {
    pointerEvents: 'none'
  },
}));

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" in={true} {...props} ref={ref} mountOnEnter unmountOnExit />;
});

export default function ResponsiveDialogManager(props) {
  const { onClose, selectedValue, open } = props;
  const { width } = useViewport();

  const handleClose = useCallback(() => {
    onClose(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  return (
    <StyledDialog
      fullScreen={width <= 1024 || props.fullScreen}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      disableEscapeKeyDown={props.noClose}
    >
      <AppBar
        enableColorOnDark
        className={classes.appBar}
      >
        <Toolbar
          className={props.mobile ? classes.appBarMobile : null}
        >
          {!props.noClose &&
            <IconButton
              edge="start"
              disableRipple
              color="inherit"
              onClick={() => handleClose()}
              aria-label="close"
              size="large">
              <CloseIcon className={classes.iconEventsBlocker} />
            </IconButton>
          }
          <div className={classes.toolbarContainer}>
            <div>
              <Typography variant="h6" className={classes.title}>
                {props.title}
              </Typography>
            </div>
            {props.toolbarActions &&
              <div>
                {props.toolbarActions}
              </div>
            }
          </div>
        </Toolbar>
      </AppBar>
      <Container
        className={classes.overflowMod}
        disableGutters={props.disableGutters}
        sx={{ maxWidth: width > 1024 && props.fullScreen && 'none' }}
      >
        {props.renderer}
      </Container>
    </StyledDialog>
  );
}

ResponsiveDialogManager.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
