import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import Logo from "../../assets/mda_powered_by_ads.png";
import Link from "@mui/material/Link";

import { useTranslation } from "react-i18next";
import { login } from "../../reducers/user/userThunks";
import LoginForm from "./LoginForm";
import RecoveryForm from "./RecoveryForm";
import SubscribeForm from "./SubscribeForm";
import { Typography } from "@mui/material";

const PREFIX = "AuthForms";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  logo: `${PREFIX}-logo`,
};

const Root = styled("div")(({ theme }) => {
  return {
    [`& .${classes.root}`]: {
      height: "100vh",
    },

    [`&.${classes.paper}`]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "350px",
      width: "100%",
      marginBottom: "2rem",
      margin: "0 auto",
    },
    [`& .${classes.logo}`]: {
      backgroundColor: "transparent",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "center",
      maxHeight: "200px",
      [theme.breakpoints.only("xs")]: {
        display: "block",
      },
    },
  };
});

export default function AuthForms(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [authMode, setAuthMode] = useState("login");

  const handleLogin = async (usernameLogin, passwordLogin) => {
    try {
      const thunk = await dispatch(
        login({ login: usernameLogin, password: passwordLogin })
      );
      if (thunk.meta.requestStatus === "rejected")
        throw new Error("login error");
      enqueueSnackbar(t("toasts.loginSuccess"), { variant: "success" });
      props.handleCloseModal();
    } catch (err) {
      enqueueSnackbar(t("toasts.loginFailed"), { variant: "error" });
    }
  };

  return (
    <Root className={classes.paper}>
      <img src={Logo} alt="artDesignStory logo" className={classes.logo} />
      {authMode === "login" ? (
        <LoginForm
          handleLogin={handleLogin}
          authMode={authMode}
          setAuthMode={setAuthMode}
          handleCloseModal={props.handleCloseModal}
        />
      ) : authMode === "recovery" ? (
        <RecoveryForm
          authMode={authMode}
          setAuthMode={setAuthMode}
          handleCloseModal={props.handleCloseModal}
        />
      ) : authMode === "register" ? (
        <SubscribeForm
          authMode={authMode}
          handleLogin={handleLogin}
          setAuthMode={setAuthMode}
          handleCloseModal={props.handleCloseModal}
        />
      ) : null}
      <Box mt={5}>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Copyright © "}
          <Link href={"http://artdesignstory.fr"} underline="hover">
            ArtDesignStory
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Box>
    </Root>
  );
}
