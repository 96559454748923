import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Footer from "./Footer";
import { playersService } from "../../services";
import SideBar from "./SideBar";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { playingStatus } from "../../reducers/players/playersSlice";

const PREFIX = 'ImageElement';

const classes = {
  imageContainer: `${PREFIX}-imageContainer`,
  image: `${PREFIX}-image`,
  title: `${PREFIX}-title`,
  elementDescriptiontt: `${PREFIX}-elementDescriptiontt`,
  elementDescriptionin: `${PREFIX}-elementDescriptionin`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.imageContainer}`]: {
    position: 'relative',
    backgroundColor: 'black',
    width: '100%',
    height: '100%',
    scrollSnapAlign: 'start',
    zIndex: '90',
  },

  [`& .${classes.image}`]: {
    backgroundColor: 'black',
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      objectFit: 'contain',
    },
    // objectFit: 'cover',
    objectFit: 'contain',
    // objectPosition: 'top',
  },

  [`& .${classes.title}`]: {
    textAlign: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '90%',
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translateX(-50%)'

  },
  [`& .${classes.elementDescriptiontt}`]: {
    width: '95%',
    color: 'white',
    position: 'absolute',
    bottom: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxHeight: '32px',
    zIndex: '15',
  },
  [`& .${classes.elementDescriptionin}`]: {
    width: '73%',
    color: 'white',
    position: 'absolute',
    bottom: '4rem',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxHeight: '32px',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
}));

const ImageElement = ({
  url,
  token,
  verticalPlayer,
  isVisible,
  isActive,
}) => {

  const dispatch = useDispatch()
  const currentElement = useSelector(state => state.players.playlist[state.players.currentPlayingIndex])
  const firstTime = useSelector(state => state.players.firstTime);
  const [once, setOnce] = useState(false);

  useEffect(() => {
    if (isActive && !firstTime)
      dispatch(playingStatus(true))
  }, [isActive, firstTime, dispatch])

  useEffect(() => {
    if (isVisible) {
      if (!once) {
        playersService.playerContentSeen(token, currentElement.id);
      }
      setOnce(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return (
    <Root
      className={classes.imageContainer}
    >
      {/* {currentElement.description ?
        <Typography className={verticalPlayer ? classes.elementDescriptiontt : classes.elementDescriptionin}>{currentElement.description}</Typography>
        :
        null
      } */}
      <img
        src={url}
        alt={`${currentElement.pushedBy?.login || 'pushed by someone'}`}
        // ref={imageRef}
        className={classes.image}
      />
      <Footer
        verticalPlayer={verticalPlayer}
      />
      <SideBar token={token} />
    </Root>
  );
};
export default ImageElement;
