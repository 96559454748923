import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';
import ResponsiveDialogManager from '../responsiveDialogManager/ResponsiveDialogManager';
import ConditionsDisplayer from '../conditionsDisplayer/ConditionsDisplayer';

const PREFIX = 'ConfirmationDialog';

const classes = {
  linkColor: `${PREFIX}-linkColor`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.linkColor}`]: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  }
}));

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" {...props} ref={ref}  />;
});

export default function ConfirmationDialog(props) {

  const { t } = useTranslation();
  const [previewDocument, setPreviewDocument] = React.useState(null);

  const handleClose = (e, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return false;
    } else {
      if (props.customClose) {
        props.customClose();
      }
      props.setOpen(false);
      if (e)
        e.stopPropagation();
    }
  };

  const onAgreeClose = (e, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return false;
    } else {
      props.setOpen(false);
      if (e)
        e.stopPropagation();
    }
  };

  const onAgree = (e) => {
    e.stopPropagation();
    props.onAgree();
    onAgreeClose();
  }

  const texts = {
    termsOfUseDataProtection: {
      touAndDataProtection: t('oauth.confirm.touAndDataProtection'),
      confirmText: t('oauth.confirm.confirmText'),
      tou: t('oauth.confirm.tou'),
      and: t('oauth.confirm.and'),
      dataProtection: t('oauth.confirm.dataProtection'),
      accept: t('oauth.confirm.accept'),
      reject: t('oauth.confirm.reject'),
    },
  }

  return (
    <Root>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <ResponsiveDialogManager
          title={!previewDocument ? '' : previewDocument.includes('pc') ? t('account.dataProtection') : t('account.termsOfUse')}
          open={!previewDocument ? false : true}
          onClose={() => setPreviewDocument(null)}
          renderer={<ConditionsDisplayer conditionName={previewDocument} />}
          disableGutters
        />
        <DialogTitle id="alert-dialog-slide-title">
          {texts[props.mode]['touAndDataProtection']}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {texts[props.mode]['confirmText']}
            <span onClick={() => setPreviewDocument("cgu")} className={classes.linkColor}>
              {texts[props.mode]['tou']}
            </span>
            {texts[props.mode]['and']}
            <span onClick={() => setPreviewDocument("pc")} className={classes.linkColor}>
              {texts[props.mode]['dataProtection']}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={e => handleClose(e)} color="primary">
            {texts[props.mode]['reject']}
          </Button>
          <Button onClick={e => onAgree(e)} color="primary">
            {texts[props.mode]['accept']}
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
}
