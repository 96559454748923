import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import NotFound from '../views/notFound/NotFound';
import { history } from "../helpers";
import PlayersManager from '../views/playersManager/PlayersManager';
import ModalManager from '../components/modalManager/ModalManager';

function AppRoutes() {
  return (
    <BrowserRouter history={history}>
      <ModalManager />
      <Routes>
        <Route path="play/*" element={<PlayersManager />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export { AppRoutes };
