import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Button, Typography } from "@mui/material";
import Footer from "./Footer";
import { playersService } from "../../services";
import { useTranslation } from 'react-i18next';
import SideBar from "./SideBar";
import ErrorIcon from '@mui/icons-material/Error';
import { useDispatch, useSelector } from "react-redux";
import { playingStatus } from "../../reducers/players/playersSlice";

const PREFIX = 'LinkElement';

const classes = {
  linkContainer: `${PREFIX}-linkContainer`,
  fullLink: `${PREFIX}-fullLink`,
  link: `${PREFIX}-link`,
  thumbnailContainer: `${PREFIX}-thumbnailContainer`,
  thumbnail: `${PREFIX}-thumbnail`,
  iframeButton: `${PREFIX}-iframeButton`,
  ButtonDecoration: `${PREFIX}-ButtonDecoration`,
  title: `${PREFIX}-title`,
  elementDescriptiontt: `${PREFIX}-elementDescriptiontt`,
  elementDescriptionin: `${PREFIX}-elementDescriptionin`,
  errorContainer: `${PREFIX}-errorContainer`,
  errorContent: `${PREFIX}-errorContent`,
  errorIcon: `${PREFIX}-errorIcon`,
  errorText: `${PREFIX}-errorText`,

};

const StyledDiv = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.linkContainer}`]: {
    position: 'relative',
    backgroundColor: 'black',
    width: '100%',
    height: '100%',
    scrollSnapAlign: 'start',
    zIndex: '90',
  },

  [`& .${classes.fullLink}`]: {
    backgroundColor: 'black',
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },

  [`& .${classes.link}`]: {
    width: '100%',
    minWidth: '1440px',
    height: '900px',
    pointerEvents: 'none',
  },

  [`& .${classes.thumbnailContainer}`]: {
    width: 'calc(1440px * 0.25)',
    height: 'calc(900px * 0.25)',
    display: 'inline-block',
    overflow: 'hidden',
    background: '#f9f9f9',
    position: 'absolute',
    left: '50%',
    top: '40%',
    transform: 'translate(-50%, -40%)',
    '&::before': {
      position: 'absolute',
      left: "calc(50% - 16px)",
      top: "calc(50% - 18px)",
      opacity: 0.2,
      display: 'block',
      'msZoom': 2,
      'OTransform': 'scale(2)',
      'MozTransform': 'scale(2)',
      'WebkitTransform': 'scale(2)',
      content: 'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiBoZWlnaHQ9IjMycHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnIGlkPSJwaG90b18xXyI+PHBhdGggZD0iTTI3LDBINUMyLjc5MSwwLDEsMS43OTEsMSw0djI0YzAsMi4yMDksMS43OTEsNCw0LDRoMjJjMi4yMDksMCw0LTEuNzkxLDQtNFY0QzMxLDEuNzkxLDI5LjIwOSwwLDI3LDB6ICAgIE0yOSwyOGMwLDEuMTAyLTAuODk4LDItMiwySDVjLTEuMTAzLDAtMi0wLjg5OC0yLTJWNGMwLTEuMTAzLDAuODk3LTIsMi0yaDIyYzEuMTAyLDAsMiwwLjg5NywyLDJWMjh6IiBmaWxsPSIjMzMzMzMzIi8+PHBhdGggZD0iTTI2LDRINkM1LjQ0Nyw0LDUsNC40NDcsNSw1djE4YzAsMC41NTMsMC40NDcsMSwxLDFoMjBjMC41NTMsMCwxLTAuNDQ3LDEtMVY1QzI3LDQuNDQ3LDI2LjU1Myw0LDI2LDR6ICAgIE0yNiw1djEzLjg2OWwtMy4yNS0zLjUzQzIyLjU1OSwxNS4xMjMsMjIuMjg3LDE1LDIyLDE1cy0wLjU2MSwwLjEyMy0wLjc1LDAuMzM5bC0yLjYwNCwyLjk1bC03Ljg5Ni04Ljk1ICAgQzEwLjU2LDkuMTIzLDEwLjI4Nyw5LDEwLDlTOS40NCw5LjEyMyw5LjI1LDkuMzM5TDYsMTMuMDg3VjVIMjZ6IE02LDE0LjZsNC00LjZsOC4wNjYsOS4xNDNsMC41OCwwLjY1OEwyMS40MDgsMjNINlYxNC42eiAgICBNMjIuNzQsMjNsLTMuNDI4LTMuOTU1TDIyLDE2bDQsNC4zNzlWMjNIMjIuNzR6IiBmaWxsPSIjMzMzMzMzIi8+PHBhdGggZD0iTTIwLDEzYzEuNjU2LDAsMy0xLjM0MywzLTNzLTEuMzQ0LTMtMy0zYy0xLjY1OCwwLTMsMS4zNDMtMywzUzE4LjM0MiwxMywyMCwxM3ogTTIwLDhjMS4xMDIsMCwyLDAuODk3LDIsMiAgIHMtMC44OTgsMi0yLDJjLTEuMTA0LDAtMi0wLjg5Ny0yLTJTMTguODk2LDgsMjAsOHoiIGZpbGw9IiMzMzMzMzMiLz48L2c+PC9zdmc+")',
    },
  },
  [`& .${classes.errorContainer}`]: {
    position: 'relative',
    backgroundColor: 'black',
    width: '100%',
    height: '100%',
  },
  [`& .${classes.errorContent}`]: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '80%',
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translateX(-50%)'
  },

  [`& .${classes.errorIcon}`]: {
    color: 'rgba(255, 255, 255, 0.7)',
    width: '130px',
    height: '130px',
  },

  [`& .${classes.errorText}`]: {
    marginTop: '1rem',
    textAlign: 'center',
  },
  [`& .${classes.thumbnail}`]: {
    width: '100%',
    minWidth: '1440px',
    height: '900px',
    position: 'relative',
    'msZoom': '0.25',
    'MozTransform': 'scale(0.25)',
    'MozTransformOrigin': '0 0',
    'OTransform': 'scale(0.25)',
    'OTransformOrigin': '0 0',
    'WebkitTransform': 'scale(0.25)',
    'WebkitTransformOrigin': '0 0',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    '& > iframe': {
      opacity: 0,
      transition: 'all 300ms ease-in-out',
    }
  },

  [`& .${classes.iframeButton}`]: {
    position: 'absolute',
    bottom: '25%',
    left: '50%',
    transform: 'translateX(-50%)'
  },

  [`& .${classes.ButtonDecoration}`]: {
    textDecoration: 'none'
  },

  [`& .${classes.title}`]: {
    textAlign: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '90%',
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  [`& .${classes.elementDescriptiontt}`]: {
    width: '95%',
    color: 'white',
    position: 'absolute',
    bottom: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxHeight: '32px',
    zIndex: '15',
  },
  [`& .${classes.elementDescriptionin}`]: {
    width: '73%',
    position: 'absolute',
    bottom: '2rem',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxHeight: '32px',
  },
}))

const LinkElement = ({
  url,
  token,
  verticalPlayer,
  isVisible,
  isActive,
}) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [once, setOnce] = useState(false);
  const [iFrameError, setIframeError] = useState(false);

  const currentElement = useSelector(state => state.players.playlist[state.players.currentPlayingIndex]);
  const firstTime = useSelector(state => state.players.firstTime);

  useEffect(() => {
    if (isActive && !firstTime)
      dispatch(playingStatus(true))
  }, [isActive, firstTime, dispatch])

  useEffect(() => {
    if (isVisible) {
      if (!once) {
        playersService.playerContentSeen(token, currentElement.id);
      }
      setOnce(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return (
    <StyledDiv
      className={classes.linkContainer}
    >
      <div className={classes.fullLink}>
        {/* {currentElement.description ?
          <Typography className={verticalPlayer ? classes.elementDescriptiontt : classes.elementDescriptionin}>{currentElement.description}</Typography>
          :
          null
        } */}
        {iFrameError ?
          <div className={classes.errorContainer}>
            <div className={classes.errorContent}>
            <ErrorIcon className={classes.errorIcon} />
            <Typography variant="h5" className={classes.errorText}>
              {t('player.linkUnavailableTitle')}
            </Typography>
            <Typography variant="body1" className={classes.errorText}>
              {t('player.linkUnavailable')}
            </Typography>
          </div>
          </div>
          :
          <div className={classes.thumbnailContainer}>
            <div className={classes.thumbnail}>
              <iframe
                className={classes.link}
                src={url}
                allowFullScreen={false}
                scrolling="no"
                title="test"
                id={`iframe-${currentElement.id}`}
                frameBorder={0}
                onLoad={(e) => {
                  if (document.getElementById(`iframe-${currentElement.id}`).contentWindow.length > 0)
                    document.getElementById(`iframe-${currentElement.id}`).style.opacity = 1;
                  else {
                    setIframeError(true)
                  }
                }}
              />
            </div>
          </div>
        }
        <a
          href={url}
          className={classes.ButtonDecoration}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.iframeButton}
          >
            {t('player.visitButton')}
          </Button>
        </a>
      </div>
      <Footer
        verticalPlayer={verticalPlayer}
      />
      <SideBar token={token} />
    </StyledDiv>
  );
};
export default LinkElement;
