import { createSlice } from '@reduxjs/toolkit'
import { fetchContent, likeContent, unlikeContent } from './playersThunks'

export const playersSlice = createSlice({
  name: 'players',
  initialState: {
    loading: false,
    muted: true,
    playlist: null,
    error: null,
    playing: false,
    firstTime: true,
    currentPlayingIndex: 0,
    modalType: null,
  },
  reducers: {
    closeModal: (state) => {
      state.modalType = null;
      state.firstTime = false;
      state.playing = true;
    },
    openModal: (state, action) => {
      state.modalType = action.payload;
      state.playing = false;
    },
    updateElementIndex: (state, action) => {
      state.currentPlayingIndex = action.payload;
    },
    firstTimeStatus: (state) => {
      state.firstTime = !state.firstTime;
      state.muted = false;
    },
    playingStatus: (state, action) => {
      state.playing = action.payload || !state.playing;
    },
    changeMute: (state) => {
      state.muted = !state.muted;
    },
    messageHasBeenRead: (state, action) => {
      state.playlist[action.payload].read = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContent.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchContent.fulfilled, (state, action) => {
        state.loading = false;
        state.playlist = [...action.payload].map(item => ({ ...item, like: false, read: false, countLike: item.countLike || 0 }));
        // state.muted = false;
      })
      .addCase(fetchContent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error
      })
      .addCase(likeContent.pending, () => { })
      .addCase(likeContent.fulfilled, (state, action) => {
        state.playlist[action.payload].like = true;
        state.playlist[action.payload].countLike += 1;
      })
      .addCase(likeContent.rejected, () => { })
      .addCase(unlikeContent.pending, () => { })
      .addCase(unlikeContent.fulfilled, (state, action) => {
        state.playlist[action.payload].like = false;
        state.playlist[action.payload].countLike -= 1;
      })
      .addCase(unlikeContent.rejected, () => { })
  },
})

// Action creators are generated for each case reducer function
export const { changeMute,
  messageHasBeenRead,
  openModal,
  closeModal,
  updateElementIndex,
  firstTimeStatus,
  playingStatus,
  likeElement,
  unlikeElement,
} = playersSlice.actions

export default playersSlice.reducer