import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Checkbox, CircularProgress, Fade, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { styled } from '@mui/material/styles';
import i18next from "i18next";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import PasswordStrengthBar from "react-password-strength-bar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import zxcvbn from "zxcvbn";
import { register } from "../../reducers/user/userThunks";
import { userServices } from "../../services";
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useImmer } from "use-immer";

import { createLegalAgeDate, isEmailValid, useDebounce } from "../../helpers";
import OAuth from "../oAuth/OAuth";
import ResponsiveDialogManager from "../responsiveDialogManager/ResponsiveDialogManager";
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import ConditionsDisplayer from "../conditionsDisplayer/ConditionsDisplayer";

const PREFIX = 'SubscribeForm';

const classes = {
  form: `${PREFIX}-form`,
  linkColor: `${PREFIX}-linkColor`,
  submitButton: `${PREFIX}-submit`,
  loginLink: `${PREFIX}-loginLink`,
  inputStyle: `${PREFIX}-inputStyle`,
  isUsedColor: `${PREFIX}-isUsedColor`,
  minHeightSize: `${PREFIX}-minHeightSize`,
  formControlSize: `${PREFIX}-formControlSize`,
  gridDisplay: `${PREFIX}-gridDisplay`,
  checkBoxDisplay: `${PREFIX}-checkBoxDisplay`,
  loginBox: `${PREFIX}-loginBox`,
  legalAgeText: `${PREFIX}-legalAgeText`,
  agePickerContainer: `${PREFIX}-agePickerContainer`,
  iconEventsBlocker: `${PREFIX}-iconEventsBlocker`
}
const StyledFade = styled(Fade)((
  {
    theme
  }
) => {
  return {
    [`& .${classes.form}`]: {
      width: '100%', // Fix IE 11 issue.
      position: 'relative',
      top: 0,
    },
    [`& .${classes.linkColor}`]: {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    },
    [`& .${classes.submitButton}`]: {
      margin: '1rem 0',
    },
    [`& .${classes.loginLink}`]: {
      color: '#D3AF5F',
      cursor: 'pointer',
      fontSize: '14px',
      '&:hover': {
        textDecoration: 'underline',
      }
    },
    [`& .${classes.isUsedColor}`]: {
      color: '#F44336'
    },
    [`& .${classes.minHeightSize}`]: {
      minHeight: '375px'
    },
    [`& .${classes.formControlSize}`]: {
      width: '100%'
    },
    [`& .${classes.gridDisplay}`]: {
      display: 'flex',
      flexDirection: 'row'
    },
    [`& .${classes.checkBoxDisplay}`]: {
     alignItems: 'flex-start',
     padding: '0 5px 0 0',
     
    },
    [`& .${classes.loginBox}`]: {
      textAlign: 'center',
      marginBottom: '10px'
    },
    [`& .${classes.legalAgeText}`]: {
      display: 'flex',
      alignItems: 'center'
    },
    [`& .${classes.agePickerContainer}`]: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '1rem',
      width: '100%',
      '& > div ': {
        marginTop: 0,
        marginBottom: '16px',
      }
    },
    [`& .${classes.iconEventsBlocker}`]: {
      pointerEvents: 'none'
    },
  };
});

const SubscribeForm = ({
  authMode,
  handleLogin,
  setAuthMode,
  handleCloseModal
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const location = useLocation();

  const [user, setUser] = useImmer({
    login: '',
    email: '',
    password: '',
    passwordScore: 0,
    birthdate: null,
    showPassword: false,
    allowExtraEmails: false,
    locale: i18next.language.substring(0, 2),
  });
  const [isLoginSearching, setIsLoginSearching] = useState(false);
  const [isEmailSearching, setIsEmailSearching] = useState(false);
  const [isLoginUsed, setIsLoginUsed] = useState(null);
  const [isEmailUsed, setIsEmailUsed] = useState(null);
  const [legalAge, setLegalAge] = useState(false);
  const debouncedSearchLogin = useDebounce(user.login, 1000);
  const debouncedSearchEmail = useDebounce(user.email, 1000);
  const [previewDocument, setPreviewDocument] = useState(false);
  const [isAgree, setIsAgree] = useState(false);

  const loading = useSelector(state => state.user.loading);

  useEffect(
    () => {
      if (debouncedSearchLogin) {
        setIsLoginSearching(true);
        userServices.checkLoginExist(debouncedSearchLogin).then(results => {
          setIsLoginSearching(false);
          // Filter out results
          setIsLoginUsed(results);
        });
      }

    },
    [debouncedSearchLogin] // Only call effect if debounced search term changes
  );

  useEffect(
    () => {
      if (debouncedSearchEmail && isEmailValid(user.email)) {
        setIsEmailSearching(true);
        userServices.checkEmailExist(debouncedSearchEmail).then(results => {
          setIsEmailSearching(false);
          // Filter out results
          setIsEmailUsed(results);
        });
      }
    },
    [debouncedSearchEmail, user.email]
  )

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === 'password') {
      let score = 0;
      let result = null;
      result = zxcvbn(value, []);
      ({ score } = result)
      setUser(user => {
        user[name] = value;
        user.passwordScore = score;
      })
    } else {
      setUser(user => {
        user[name] = value;
      });
    }
  }

  const handleClickExtraEmails = useCallback((e) => {
    setUser(user => {
      user.allowExtraEmails = !user.allowExtraEmails
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]); 
  const handleClickShowPassword = useCallback((e) => {
    setUser(user => {
      user.showPassword = !user.showPassword
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const userIsValid = useCallback(() => {
    return user.login && isEmailValid(user.email) && user.password && user.passwordScore >= 2 && !isLoginUsed && !isEmailUsed && user.birthdate && (new Date(user.birthdate) <= createLegalAgeDate(0, 0, -15));
  }, [user, isLoginUsed, isEmailUsed])

  async function handleSubmit(e) {
    e.preventDefault();
    if (user.login && user.email && user.password && user.birthdate) {
      try {
        const thunk = await dispatch(register({ ...user, birthdate: dayjs(user.birthdate).format('YYYY-MM-DD') }))
        if (thunk.meta.requestStatus === "rejected")
          throw new Error("registration error");
        handleLogin(user.email, user.password)
      }
      catch {
        enqueueSnackbar(t('toasts.registrationFailed'), { variant: 'error', })
      }
    }
  }

  return (
    <StyledFade in={authMode ? true : false} timeout={800}>
      <div className={classes.minHeightSize}>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl variant="standard" className={classes.formControlSize}>
                <InputLabel htmlFor="login">{t('authForms.signin.username')}</InputLabel>
                <Input
                  required
                  fullWidth
                  name="login"
                  type="text"
                  id="login"
                  autoComplete="login"
                  onChange={handleChange}
                  error={isLoginUsed}
                  endAdornment={(isLoginUsed !== null || isLoginSearching) &&
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" size="large">
                        {isLoginSearching ? <CircularProgress className={classes.iconEventsBlocker} /> : isLoginUsed ? <ClearIcon className={classes.iconEventsBlocker} /> : <CheckIcon className={classes.iconEventsBlocker} />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {isLoginUsed &&
                  <Typography variant="body2"
                    className={classes.isUsedColor}>{t('authForms.signin.errorMessages.login')}
                  </Typography>
                }
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" className={classes.formControlSize}>
                <InputLabel htmlFor="email">
                  {t('authForms.signin.email')}
                </InputLabel>
                <Input
                  required
                  fullWidth
                  name="email"
                  type="text"
                  id="email"
                  autoComplete="email"
                  onChange={handleChange}
                  error={isEmailUsed}
                  endAdornment={(isEmailUsed !== null || isEmailSearching) &&
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" size="large">
                        {isEmailSearching ? <CircularProgress className={classes.iconEventsBlocker} /> : isEmailUsed ? <ClearIcon className={classes.iconEventsBlocker} /> : <CheckIcon className={classes.iconEventsBlocker} />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {isEmailUsed &&
                  <Typography variant="body2"
                    className={classes.isUsedColor}>{t('authForms.signin.errorMessages.email')}
                  </Typography>
                }
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" className={classes.formControlSize}>
                <InputLabel htmlFor="password">
                  {t('authForms.signin.password')}
                </InputLabel>
                <Input
                  required
                  fullWidth
                  name="password"
                  type={user.showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword()}
                        size="large">
                        {user.showPassword ? <Visibility className={classes.iconEventsBlocker} /> : <VisibilityOff className={classes.iconEventsBlocker} />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={handleChange}
                />
                <PasswordStrengthBar
                  password={user.password}
                  shortScoreWord={t('updatePasswordForm.tooShort')}
                  scoreWords={[
                    t('updatePasswordForm.weak'),
                    t('updatePasswordForm.weak'),
                    t('updatePasswordForm.ok'),
                    t('updatePasswordForm.good'),
                    t('updatePasswordForm.strong')
                  ]}
                />
              </FormControl>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={user.locale}>
              <div className={classes.agePickerContainer}>
                <DatePicker
                  disableFuture
                  openTo="year"
                  views={['year', 'month', 'day']}
                  label={t('authForms.signin.birthdate')}
                  maxDate={createLegalAgeDate(0, 0, -15)}
                  value={user.birthdate}
                  onChange={(newValue) => {
                    setUser(user => {
                      user.birthdate = newValue;
                    })
                  }}
                  renderInput={(params) => <TextField {...params} placeholder="birthdate" />}
                />
              </div>
            </LocalizationProvider>
            <Grid item xs={12} className={classes.gridDisplay}>
              <Checkbox value="checkLegalAge" color="primary" className={classes.checkBoxDisplay} onChange={e => setLegalAge(e.target.checked)} checked={legalAge} />
              <Typography className={classes.legalAgeText} variant="body1">{t('authForms.signin.legalAge')}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridDisplay}>
              <Checkbox value="allowExtraEmails" className={classes.checkBoxDisplay} onChange={handleClickExtraEmails} />
              <Typography variant="body1">{t('authForms.signin.marketAndUpdates')}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridDisplay}>
              <Checkbox value="agreeConditions" className={classes.checkBoxDisplay} onChange={e => setIsAgree(e.target.checked)} checked={isAgree} />
              <ResponsiveDialogManager
                mobile={location.pathname.indexOf("mobile") > 0}
                title={!previewDocument ? '' : previewDocument.includes('pc') ? t('conditions.dataProtection') : t('conditions.termsOfUse')}
                open={!previewDocument ? false : true}
                fullScreen
                onClose={() => setPreviewDocument(null)}
                renderer={<ConditionsDisplayer conditionName={previewDocument} />}
              />
              <Typography variant="body1">
                {t('authForms.signin.agreeTou')}
                <span onClick={() => setPreviewDocument("cgu")} className={classes.linkColor}>
                  {t('authForms.signin.termsOfUse')}
                </span>
                {t('authForms.signin.agreeAnd')}
                <span onClick={() => setPreviewDocument("pc")} className={classes.linkColor}>
                  {t('authForms.signin.dataProtection')}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submitButton}
            disabled={!userIsValid() || !isAgree || !legalAge || loading}
          >
            {loading ? <CircularProgress /> : t('authForms.signin.signinButton')}
          </Button>
          <OAuth handleCloseModal={handleCloseModal} />
          <Box className={classes.loginBox}>
            <Typography className={classes.loginLink} onClick={() => {
              setAuthMode("login")
            }}>
              {t('authForms.switchLinks.login')}
            </Typography>
          </Box>
        </form>
      </div>
    </StyledFade>
  );
}

export default SubscribeForm;