import React from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/logo_ads.png';
import Admin from '../../assets/logo.png';
import { Container } from '@mui/system';

const PREFIX = 'AccessingMessage';

const classes = {
  root: `${PREFIX}-root`,
  margin: `${PREFIX}-margin`,
  topImg: `${PREFIX}-topImg`,
  bottomImg: `${PREFIX}-bottomImg`,

};

const MessageContainer = styled(Container)(({ theme }) => {
  return ({
    [`& .${classes.root}`]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    },
    [`& .${classes.margin}`]: {
      margin: '1rem'
    },
    [`& .${classes.topImg}`]: {
      maxWidth: "150px",
      position: "absolute",
      left: "50%",
      bottom: "1rem",
      transform: "translateX(-75px)",
    },
    [`& .${classes.bottomImg}`]: {
      display: "none"
    }
  })
});

const AccessingMessage = ({ text, loader, error }) => {
  const { t } = useTranslation();

  return (
    <MessageContainer>
      <div className={classes.root}>
        {loader ?
          <CircularProgress size={50} />
          :
          <Typography
            variant="h5"
            sx={{ color: error ? '#e02323' : '' }}
            className={classes.margin}
          >
            {text}
          </Typography>
        }
        <Typography variant="h6" className={classes.margin}>
          {t('adminAccess')}
        </Typography>
        <Button
          onClick={() => window.location = process.env.REACT_APP_ADMIN_URL}
        >
          <img src={Admin} width={48} alt="Admin logo" />
        </Button>
        <img
          src={Logo}
          width={'30%'}
          alt="ADS logo"
          className={classes.topImg}
        />
        <img
          src="https://art-design-story-bucket-prod.s3.fr-par.scw.cloud/art-design-story/player/player.png"
          className={classes.bottomImg}
          alt="player logo"
        />
      </div>
    </MessageContainer>
  );
}

export default AccessingMessage;