import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { recoverPassword } from "../../reducers/user/userThunks";
import { isEmailValid } from "../../helpers";

const PREFIX = "RecoveryForm";

const classes = {
  form: `${PREFIX}-form`,
  errorRecoveryMessage: `${PREFIX}-errorRecoveryMessage`,
  successRecoveryMessage: `${PREFIX}-successRecoveryMessage`,
  submit: `${PREFIX}-submit`,
  recovery: `${PREFIX}-recovery`,
  loginBox: `${PREFIX}-loginBox`,
  subscribeBox: `${PREFIX}-subscribeBox`,
};

const StyledFade = styled(Fade)(({ theme }) => {
  return {
    [`& .${classes.form}`]: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      position: "relative",
      top: 0,
    },
    [`& .${classes.errorRecoveryMessage}`]: {
      color: "#F44336",
    },
    [`& .${classes.successRecoveryMessage}`]: {
      color: "#fff",
      textAlign: "center",
    },
    [`& .${classes.submit}`]: {
      margin: theme.spacing(3, 0, 2),
    },
    [`& .${classes.recovery}`]: {
      color: "#D3AF5F",
      cursor: "pointer",
      fontSize: "14px",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    [`& .${classes.loginBox}`]: {
      textAlign: "center",
      marginBottom: "10px",
    },
    [`& .${classes.subscribeBox}`]: {
      textAlign: "center",
    },
  };
});

const RecoveryForm = ({ authMode, setAuthMode, handleCloseModal }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [recoveryMail, setRecoveryMail] = useState("");

  const loading = useSelector((state) => state.user.loading);
  const recoveryError = useSelector((state) => state.user.error);
  const recoveryStatus = useSelector((state) => state.user.recoverySuccess);

  async function handleRecoverPassword(e) {
    e.preventDefault();
    try {
      const thunk = await dispatch(recoverPassword(recoveryMail));
      if (thunk.meta.requestStatus === "rejected")
        throw new Error("recovery error");
      handleCloseModal(false);
    } catch (err) {
      enqueueSnackbar(t("toasts.passwordRecoveryFailed"), { variant: "error" });
    }
  }

  return (
    <StyledFade in={authMode ? true : false} timeout={800}>
      <div
        sx={{
          minHeight:
            (recoveryError && recoveryError.length > 0) ||
            (recoveryStatus && recoveryStatus.length > 0)
              ? "285px"
              : "261px",
          paddingTop: "80px",
        }}
      >
        <form
          className={classes.form}
          noValidate
          onSubmit={handleRecoverPassword}
        >
          <TextField
            variant="outlined"
            size="medium"
            required
            fullWidth
            id="recoveryMail"
            label={t("authForms.recoverPassword.loginOrEmail")}
            name="recoveryMail"
            autoComplete="recoveryMail"
            autoFocus
            onChange={(e) => setRecoveryMail(e.target.value)}
            error={recoveryError && recoveryError.length > 0}
          />
          <Typography className={classes.errorRecoveryMessage}>
            {recoveryError}
          </Typography>
          <Typography className={classes.successRecoveryMessage}>
            {recoveryStatus}
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading || !isEmailValid(recoveryMail)}
            onClick={() =>
              enqueueSnackbar(t("toasts.emailSent"), { variant: "success" })
            }
          >
            {loading ? (
              <CircularProgress size={28} />
            ) : (
              t("authForms.recoverPassword.sendButton")
            )}
          </Button>
          <Box className={classes.loginBox}>
            <Typography
              className={classes.recovery}
              onClick={() => {
                setAuthMode("login");
              }}
            >
              {t("authForms.switchLinks.login")}
            </Typography>
          </Box>
          <Box className={classes.subscribeBox}>
            <Typography
              className={classes.recovery}
              onClick={() => {
                setAuthMode("register");
              }}
            >
              {t("authForms.switchLinks.noAccount")}
            </Typography>
          </Box>
        </form>
      </div>
    </StyledFade>
  );
};

export default RecoveryForm;
