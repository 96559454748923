import { configureStore } from '@reduxjs/toolkit'
import userSlice from '../reducers/user/userSlice';
import playersSlice from '../reducers/players/playersSlice';

export const store = configureStore({
  reducer: {
    players: playersSlice,
    user: userSlice,
  },
})
