import { createAsyncThunk } from "@reduxjs/toolkit";
import { userServices } from "../../services"

export const login = createAsyncThunk(
  'user/login',
  async (userInfos, { rejectWithValue }) => {
    const response = await userServices.login(userInfos.login, userInfos.password)
    if (!response)
        return rejectWithValue('offline')
    return response;
  }
)

export const register = createAsyncThunk(
  'user/register',
  async (userInfos, { rejectWithValue }) => {
      const response = await userServices.register(userInfos)
      if (!response)
        return rejectWithValue('offline')
      return response
  }
)

export const recoverPassword = createAsyncThunk(
  'user/recoverPassword',
  async (email, { rejectWithValue }) => {
    const response = await userServices.recoverPassword(email)
    if (!response)
      return rejectWithValue('offline')
    return response
  }
)

export const socialLogin = createAsyncThunk(
  'user/socialLogin',
  async (providerInfo, { rejectWithValue }) => {
    const response = await userServices.socialLogin(providerInfo)
    if (!response)
      return rejectWithValue('offline')
    return response
  }
)