import React, {useState} from "react";
import { styled } from '@mui/material/styles';
import { Apple, Facebook } from "@mui/icons-material";
import { Button, Container } from "@mui/material";
import GoogleIcon from "../../assets/Google__G__Logo.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "../confirmationDialog/ConfirmationDialog";
// import firebase from "firebase/compat";
import firebase from 'firebase/compat/app';
// v9 compat packages are API compatible with v8 code
import 'firebase/compat/auth';
import 'firebase/compat/firestore';  // for cloud functions
import { useSnackbar } from "notistack";
import { socialLogin } from "../../reducers/user/userThunks";

const PREFIX = 'OAuth';

const classes = {
  oauthContainer: `${PREFIX}-oauthContainer`,
  oauthButtons: `${PREFIX}-oauthButtons`,
  oauthButtonText: `${PREFIX}-oauthButtonText`,
  oauthButtonTextGoogle: `${PREFIX}-oauthButtonTextGoogle`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`&.${classes.oauthContainer}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    maxWidth: 480,
    flexWrap: "wrap",
  },

  [`& .${classes.oauthButtons}`]: {
    width: 225,
    marginTop: 10,
    marginLeft: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  [`& .${classes.oauthButtonText}`]: {
    width: "100%",
    marginLeft: "1rem",
    textAlign: "left",
    fontSize: "0.7rem",
  },

  [`& .${classes.oauthButtonTextGoogle}`]: {
    width: "100%",
    marginLeft: "1.2rem",
    textAlign: "left",
    fontSize: "0.7rem",
  }
}));

export default function OAuth(props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [provider, setProvider] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState(false)

  function signInWithProvider() {
    let zeProvider;
    if (provider === "facebook") {
      zeProvider = new firebase.auth.FacebookAuthProvider();
    }
    if (provider === "google") {
      zeProvider = new firebase.auth.GoogleAuthProvider();
    }
    if (provider === "apple") {
      zeProvider = new firebase.auth.OAuthProvider('apple.com');
    }

    firebase
      .auth()
      .signInWithPopup(zeProvider)
      .then(async (result) => {
        const credential = result.credential;
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        try {
          const thunk = await dispatch(socialLogin({
            providerId: user.uid,
            providerName: user.providerId,
            email: user.email,
            accessToken: token,
            displayName: user.displayName,
            picture: user.photoURL
          }));
          if (thunk.meta.requestStatus === "rejected")
            throw new Error("Social login error")
          enqueueSnackbar(t('toasts.loginSuccess'), { variant: 'success', })
          props.handleCloseModal();
        } catch (err) {
          enqueueSnackbar(t('toasts.loginFailed'), { variant: 'error', })
        }
      })
      .catch(function (error) {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        const credential = error.credential;
        console.error(error, errorCode, errorMessage, email, credential);
      });
  }

  return (
    <StyledContainer disableGutters className={classes.oauthContainer}>
      <ConfirmationDialog
        open={confirmationDialog}
        onAgree={() => signInWithProvider()}
        setOpen={setConfirmationDialog}
        mode="termsOfUseDataProtection"
      />
      <Button variant="outlined" size="small" className={classes.oauthButtons} color="primary"
        onClick={() => {
          setProvider("google");
          setConfirmationDialog(true);
        }}>
        <img src={GoogleIcon} width={20} height={20} alt="google logo" />
        <span className={classes.oauthButtonTextGoogle}>{t('oauth.google')}</span>
      </Button>
      <Button variant="outlined" size="small" className={classes.oauthButtons} color="primary"
        onClick={() => {
          setProvider("facebook");
          setConfirmationDialog(true);
        }}>
        <Facebook />
        <span className={classes.oauthButtonText}>{t('oauth.facebook')}</span>
      </Button>
      <Button variant="outlined" size="small" className={classes.oauthButtons} color="primary"
        onClick={() => {
          setProvider("apple");
          setConfirmationDialog(true);
        }}>
        <Apple />
        <span className={classes.oauthButtonText}>{t('oauth.apple')}</span>
      </Button>
    </StyledContainer>
  );
}
