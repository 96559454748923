import React, {useEffect, useState} from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, styled, Typography } from '@mui/material';

const PREFIX = 'ConditionsDisplayer';

const classes = {
  documentContainer: `${PREFIX}-documentContainer`,
  defaultContainer: `${PREFIX}-defaultContainer`,
  retryButton: `${PREFIX}-retryButton`
};

const ConditionsDisplayerContainer = styled('div')(({theme}) => {
  return ({
  [`& .${classes.documentContainer}`]: {
    padding: '1.5rem 0.5rem',
  },
  [`& .${classes.defaultContainer}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  [`& .${classes.retryButton}`]: {
    marginTop: '0.5rem',
  }
})
});

const ConditionsDisplayer = ({conditionName}) => {
  const {t} = useTranslation();
  const enqueueSnackbar = useSnackbar();

  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(false);

  const fetchContent = (name) => {
    const routes = {
      cgu: 'https://cg-ads.s3.fr-par.scw.cloud/CGU-v1.html',
      cgv: 'https://cg-ads.s3.fr-par.scw.cloud/CGV-v1.html',
      pc: 'https://cg-ads.s3.fr-par.scw.cloud/PC-v1.html',
    }
    fetch(routes[name])
    .then(res => {
      res.text()
      .then(html => {
        document.getElementById('conditions').innerHTML = html;
        setFetching(false);
        setError(false);
      });
    })
    .catch(err => {
      enqueueSnackbar(t('toasts.updateExternalLinksError'), { variant: 'error', });
      setFetching(false);
      setError(true);
    })
  }
  
  useEffect(() => {
    fetchContent(conditionName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionName])

  return (
    <ConditionsDisplayerContainer>
      {fetching &&
        <div className={classes.defaultContainer}>
          <CircularProgress size={50} />
          <Typography>{t('conditionsDisplayer.fetching')}</Typography>
        </div>
      }
      {error &&
        <div className={classes.defaultContainer}>
          <Typography>{t('conditionsDisplayer.failed')}</Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.retryButton}
            onClick={() => fetchContent(conditionName)}
          >
            {t('conditionsDisplayer.retryButton')}
          </Button>
        </div>
      }
      <div
        id="conditions"
        className={classes.documentContainer}
        style={{display: fetching || error ? 'none' : 'block'}}
      />
    </ConditionsDisplayerContainer>
  )
}

export default ConditionsDisplayer;