import React from "react";
import { useImmer } from "use-immer";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  Button,
  CircularProgress,
  Fade,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import OAuth from "../oAuth/OAuth";

const PREFIX = "LoginForm";

const classes = {
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  recovery: `${PREFIX}-recovery`,
  textfieldSpacing: `${PREFIX}-textfield`,
  loginSize: `${PREFIX}-loginSize`,
  recoveryBox: `${PREFIX}-recoveryBox`,
  subscribeBox: `${PREFIX}-subscribeBox`,
};

const StyledFade = styled(Fade)(({ theme }) => ({
  [`& .${classes.form}`]: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    position: "relative",
    top: 0,
  },
  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },
  [`& .${classes.recovery}`]: {
    color: "#D3AF5F",
    cursor: "pointer",
    fontSize: "14px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  [`& .${classes.textfieldSpacing}`]: {
    marginBottom: "1rem",
  },
  [`& .${classes.loginSize}`]: {
    minHeight: "261px",
  },
  [`& .${classes.recoveryBox}`]: {
    textAlign: "center",
    marginBottom: "10px",
  },
  [`& .${classes.subscribeBox}`]: {
    textAlign: "center",
  },
}));

const LoginForm = ({
  handleLogin,
  authMode,
  setAuthMode,
  handleCloseModal,
}) => {
  const { t } = useTranslation();

  const [inputs, setInputs] = useImmer({
    usernameLogin: "",
    passwordLogin: "",
  });
  const { usernameLogin, passwordLogin } = inputs;

  const loading = useSelector((state) => state.user.loading);

  const handleChangeLogin = (e) => {
    const { name, value } = e.target;
    setInputs((input) => {
      input[name] = value;
    });
  };

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    if (usernameLogin && passwordLogin) {
      handleLogin(usernameLogin, passwordLogin);
    }
  };

  return (
    <StyledFade in={authMode ? true : false} timeout={800}>
      <div className={classes.loginSize}>
        <form className={classes.form} noValidate onSubmit={handleSubmitLogin}>
          <TextField
            variant="outlined"
            size="medium"
            required
            fullWidth
            id="usernameLogin"
            label={t("authForms.login.usernameOrEmail")}
            name="usernameLogin"
            autoComplete="email"
            autoFocus
            onChange={handleChangeLogin}
            className={classes.textfieldSpacing}
          />
          <TextField
            variant="outlined"
            size="medium"
            required
            fullWidth
            name="passwordLogin"
            label={t("authForms.login.password")}
            type="password"
            id="passwordLogin"
            autoComplete="current-password"
            onChange={handleChangeLogin}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={
              ((!passwordLogin || !usernameLogin) && !loading) || loading
            }
          >
            {loading ? <CircularProgress /> : t("authForms.login.loginButton")}
          </Button>
          <OAuth handleCloseModal={handleCloseModal} />
          <Box className={classes.recoveryBox}>
            <Typography
              className={classes.recovery}
              onClick={() => {
                setAuthMode("recovery");
              }}
            >
              {t("authForms.switchLinks.forgottenPassword")}
            </Typography>
          </Box>
          <Box className={classes.subscribeBox}>
            <Typography
              className={classes.recovery}
              onClick={() => {
                setAuthMode("register");
              }}
            >
              {t("authForms.switchLinks.noAccount")}
            </Typography>
          </Box>
        </form>
      </div>
    </StyledFade>
  );
};

export default LoginForm;
