import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { Typography, LinearProgress, CircularProgress } from "@mui/material";
import Footer from "./Footer";
import SideBar from "./SideBar";
import Logo from "../../assets/logo_ads.png";
import PlayLogo from "../../assets/play.png";
import { playersService } from "../../services";
import ErrorIcon from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  firstTimeStatus,
  playingStatus,
} from "../../reducers/players/playersSlice";
import Hls from "hls.js";

const PREFIX = "VideoElement";

const classes = {
  video: `${PREFIX}-video`,
  videoPlayer: `${PREFIX}-videoPlayer`,
  videoPlayButton: `${PREFIX}-videoPlayButton`,
  playButtonImage: `${PREFIX}-playButtonImage`,
  logoAds: `${PREFIX}-logoAds`,
  title: `${PREFIX}-title`,
  progressBar: `${PREFIX}-progressBar`,
  errorContainer: `${PREFIX}-errorContainer`,
  errorContent: `${PREFIX}-errorContent`,
  errorIcon: `${PREFIX}-errorIcon`,
  errorText: `${PREFIX}-errorText`,
  elementDescriptiontt: `${PREFIX}-elementDescriptiontt`,
  elementDescriptionin: `${PREFIX}-elementDescriptionin`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.video}`]: {
    position: "relative",
    backgroundColor: "black",
    width: "100%",
    height: "100%",
    scrollSnapAlign: "start",
    zIndex: "90",
  },

  [`& .${classes.videoPlayer}`]: {
    backgroundColor: "black",
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },

  [`& .${classes.videoPlayButton}`]: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    "& > .MuiSvgIcon-root": {
      fontSize: "4rem !important",
      cursor: "pointer",
    },
  },

  [`& .${classes.playButtonImage}`]: {
    maxWidth: "300px",
    width: "100%",
  },

  [`& .${classes.logoAds}`]: {
    width: "150px",
    position: "absolute",
    left: "50%",
    top: "2.5rem",
    transform: "translateX(-50%)",
  },

  [`& .${classes.title}`]: {
    textAlign: "center",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "90%",
    position: "absolute",
    top: "0",
    left: "50%",
    transform: "translateX(-50%)",
  },

  [`& .${classes.progressBar}`]: {
    position: "absolute",
    bottom: "0",
    width: "100%",
  },

  [`& .${classes.errorContainer}`]: {
    position: "relative",
    backgroundColor: "black",
    width: "100%",
    height: "100%",
  },

  [`& .${classes.errorContent}`]: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "80%",
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translateX(-50%)",
  },

  [`& .${classes.errorIcon}`]: {
    color: "rgba(255, 255, 255, 0.7)",
    width: "130px",
    height: "130px",
  },

  [`& .${classes.errorText}`]: {
    marginTop: "1rem",
    textAlign: "center",
  },
  [`& .${classes.elementDescriptiontt}`]: {
    width: "95%",
    color: "white",
    position: "absolute",
    bottom: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    textAlign: "center",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxHeight: "32px",
    zIndex: "15",
  },
  [`& .${classes.elementDescriptionin}`]: {
    width: "73%",
    color: "white",
    position: "absolute",
    bottom: "2rem",
    left: "50%",
    transform: "translateX(-50%)",
    textAlign: "center",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxHeight: "32px",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
}));

const VideoElement = ({
  url,
  token,
  currentIndex,
  disableProgress,
  verticalPlayer,
  isVisible,
  isActive,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(false);
  const [videoCurrentProgress, setVideoCurrentProgress] = useState(0);
  const [error, setError] = useState(null);

  const loaderRef = useRef(true);

  const currentElement = useSelector(
    (state) => state.players.playlist[state.players.currentPlayingIndex]
  );
  const currentPlayingIndex = useSelector(
    (state) => state.players.currentPlayingIndex
  );
  const isFirstTime = useSelector((state) => state.players.firstTime);
  const isPlaying = useSelector((state) => state.players.playing);
  const muted = useSelector((state) => state.players.muted);
  const hlsRef = useRef(null);

  const onVideoClick = () => {
    if (isFirstTime) {
      dispatch(firstTimeStatus());
      if (!played) {
        playersService.playerContentSeen(token, currentElement.id);
        setPlayed(true);
      }
    }
    if (playing) {
      dispatch(playingStatus(false));
      videoRef.current.pause();
      setPlaying(false);
    } else {
      videoRef.current
        .play()
        .then((e) => {
          loaderRef.current = false;
          dispatch(playingStatus(true));
          setPlaying(true);
        })
        .catch((err) => {
          console.log("err 1 =>", err);
          setError(true);
        });
    }
  };

  useEffect(() => {
    if (currentPlayingIndex === currentIndex && isActive) {
      if (!isPlaying) {
        videoRef.current.pause();
        setPlaying(false);
      } else {
        if (Hls.isSupported() && url.endsWith(".m3u8")) {
          const hls = new Hls();
          hls.loadSource(url);
          if (!hlsRef.current) {
            hlsRef.current = hls;
            hls.attachMedia(videoRef.current);
          }
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            videoRef.current.play().then((e) => {
              loaderRef.current = false;
              setPlaying(true);
            });
          });
        } else if (
          videoRef.current.canPlayType("application/vnd.apple.mpegurl")
        ) {
          videoRef.current.src = url;
          videoRef.current.addEventListener("loadedmetadata", () => {
            videoRef.current.play().then((e) => {
              loaderRef.current = false;
              setPlaying(true);
            });
          });
        } else {
          videoRef.current
            .play()
            .then((e) => {
              loaderRef.current = false;
              setPlaying(true);
            })
            .catch((err) => {
              console.log("err 2 =>", err);
              setError(true);
            });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  useEffect(() => {
    if (isActive) {
      if (!playing && !isFirstTime) {
        videoRef.current.currentTime = 0;
        if (Hls.isSupported() && url.endsWith(".m3u8")) {
          const hls = new Hls();
          hls.loadSource(url + "#t=0.01");
          hls.attachMedia(videoRef.current);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            videoRef.current
              .play()
              .then((e) => {
                loaderRef.current = false;
                dispatch(playingStatus(true));
                setPlaying(true);
              })
              .catch((err) => {
                console.log("err 3 =>", err);
                setError(true);
              });
          });
        } else if (
          videoRef.current.canPlayType("application/vnd.apple.mpegurl")
        ) {
          videoRef.current.src = url;
          videoRef.current.addEventListener("loadedmetadata", () => {
            videoRef.current
              .play()
              .then((e) => {
                loaderRef.current = false;
                dispatch(playingStatus(true));
                setPlaying(true);
              })
              .catch((err) => {
                console.log("err 3 =>", err);
                setError(true);
              });
          });
        } else {
          videoRef.current
            .play()
            .then((e) => {
              loaderRef.current = false;
              dispatch(playingStatus(true));
              setPlaying(true);
            })
            .catch((err) => {
              console.log("err 3 =>", err);
              setError(true);
            });
        }
        if (!played) {
          playersService.playerContentSeen(token, currentElement.id);
          setPlayed(true);
        }
      }
    } else {
      if (playing) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
        setPlaying(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    let tmp;
    if (!disableProgress) {
      tmp = videoRef.current.addEventListener("timeupdate", () =>
        showCurrentTime(videoRef)
      );
    }
    return () => {
      if (!disableProgress && tmp) {
        tmp.removeEventListener("timeupdate", () => showCurrentTime(videoRef));
      }
    };
  }, [disableProgress]);

  const showCurrentTime = (videoDom) => {
    setVideoCurrentProgress(
      (videoDom.current.currentTime / videoDom.current.duration) * 100
    );
  };

  return (
    <Root className={classes.video}>
      {error && (
        <div className={classes.errorContainer}>
          <div className={classes.errorContent}>
            <ErrorIcon className={classes.errorIcon} />
            <Typography variant="h5" className={classes.errorText}>
              {t("player.videoUnavailableTitle")}
            </Typography>
            <Typography variant="body1" className={classes.errorText}>
              {t("player.videoUnavailable")}
            </Typography>
          </div>
        </div>
      )}
      {/* {currentElement.description && !isFirstTime && !error ?
        <Typography className={verticalPlayer ? classes.elementDescriptiontt : classes.elementDescriptionin}>
          {currentElement.description}
        </Typography>
        :
        null
      } */}
      {loaderRef.current && isPlaying && (
        <CircularProgress
          size={80}
          sx={{
            position: "absolute",
            top: "46%",
            left: "40%",
            transform: "translate(-46%, -40%)",
          }}
        />
      )}
      <video
        className={classes.videoPlayer}
        loop
        preload="auto"
        playsInline
        ref={videoRef}
        autoPlay={!isFirstTime && currentIndex === currentPlayingIndex}
        onClick={disableProgress ? undefined : onVideoClick}
        // add #t=Time to automatically display thumbnail from the video
        src={url + "#t=0.01"}
        muted={muted}
        style={{
          display: error ? "none" : "visible",
        }}
      ></video>
      {playing && isVisible && !error && !disableProgress && (
        <LinearProgress
          variant="determinate"
          value={videoCurrentProgress}
          className={classes.progressBar}
        />
      )}
      {!error && <Footer mutable verticalPlayer={verticalPlayer} />}
      <SideBar token={token} />
      {(isFirstTime || !isPlaying) && !disableProgress && (
        <div className={classes.videoPlayButton}>
          <img
            src={PlayLogo}
            alt="Play Icon"
            className={classes.playButtonImage}
            onClick={onVideoClick}
          />
        </div>
      )}
      {isFirstTime && (
        <img src={Logo} alt="logo ArtDesignStory" className={classes.logoAds} />
      )}
    </Root>
  );
};
export default VideoElement;
