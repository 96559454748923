import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { fetchContent } from '../../reducers/players/playersThunks';
import StoriesDisplayer from '../../components/storiesDisplayer/StoriesDisplayer';
import NotFound from '../notFound/NotFound';

const PlayersManager = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const [token, setToken] = useState(null);

  const handleFetch = async (search) => {
    try {
      const thunk = await dispatch(fetchContent({ iv: search.iv, content: search.content }))
      if (thunk.meta.requestStatus === 'rejected')
        throw new Error('offline')
    } catch {
      enqueueSnackbar(t('toasts.getInfosError'), { variant: 'error' });
    }
  }

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    let tmp = {iv: search.get('iv'), content: search.get('content')}
    if (tmp.iv && tmp.content) {
      handleFetch(tmp);
      setToken(tmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="in" element={<StoriesDisplayer token={token} />} />
      <Route path="tt" element={<StoriesDisplayer token={token} direction="vertical" />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default PlayersManager;