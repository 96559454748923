import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { closeModal } from "../../reducers/players/playersSlice"
import { resetError } from "../../reducers/user/userSlice"
import AuthForms from "../authForms/AuthForms"
import ResponsiveDialogManager from "../responsiveDialogManager/ResponsiveDialogManager"
import SendMessage from "../messagesDialogContents/SendMessage"
import ReadMessage from "../messagesDialogContents/ReadMessage"

const ModalManager = () => {

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const modalInfo = useSelector(state => state.players.modalType)

  const handleClose = (close = true) => {
    if (close)
      dispatch(closeModal());
    dispatch(resetError());
  };

  return (
    <>
      {modalInfo === "auth" ?
        <ResponsiveDialogManager
          open={modalInfo ? true : false}
          renderer={
            <AuthForms handleCloseModal={handleClose} />
          }
          title={t('player.welcomeMessage')}
          onClose={handleClose} />
        : modalInfo === "message" ?
          <SendMessage />
          : modalInfo === 'comment' ?
          <ReadMessage />
          :
          null
      }
    </>
  )

}

export default ModalManager